import React from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';

export default function FadeIn({ className, children }) {
  const { ref, inView, entry } = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={className}
      css={css`
        opacity: ${(inView) ? 1 : 0};
        transform: translateY(${(inView) ? 0 : '8rem'});
        transition: opacity 1s var(--curve), transform 1.2s var(--curve);
        will-change: opacity, transform;
      `}
    >
      {children}
    </div>
  )
}
