import React, { useState, useEffect, useContext } from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';

export default function CollaboratorsTable({ collaborators }) {
  const [hover, setHover] = useState(null);

  return (
    <div>
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: ${(hover?.hoverType === 'color') ? hover.hoverColor?.value : 'transparent'};
          z-index: 1;
          transition: background-color 1s var(--curve);
          transition-delay: ${(hover?.hoverType === 'color') ? '0.6s' : '0s'};
          pointer-events: none;

          @media (max-width: 900px) {
            display: none;
          }
        `}
      >
          {collaborators.length > 0 && collaborators
            .filter((collaborator) => collaborator.hoverType === 'image')
            .map((collaborator, i) => (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                `}
              >
                <img
                  key={i}
                  css={css`
                    max-width: 60vw;
                    max-height: 80vh;
                    opacity: ${(hover === collaborator && collaborator.hoverType === 'image') ? 1 : 0};
                    transition: opacity 1s var(--curve);
                    transition-delay: ${(hover === collaborator && collaborator.hoverType === 'image') ? '0.6s' : '0s'};
                  `}
                  src={collaborator.hoverImage?.asset.gatsbyImageData.images.fallback.src}
                />
              </div>
            ))
          }
          {collaborators.length > 0 && collaborators
            .filter((collaborator) => collaborator.hoverType === 'full-bleed-image')
            .map((collaborator, i) => (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                `}
              >
                <img
                  key={i}
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    opacity: ${(hover === collaborator && collaborator.hoverType === 'full-bleed-image') ? 1 : 0};
                    transition: opacity 1s var(--curve);
                    transition-delay: ${(hover === collaborator && collaborator.hoverType === 'full-bleed-image') ? '0.6s' : '0s'};
                  `}
                  src={collaborator.hoverFullBleedImage?.asset.gatsbyImageData.images.fallback.src}
                />
              </div>
            ))
          }
      </div>
      {collaborators.length > 0 && collaborators
        .map((collaborator, i) => (
          <a
            key={i}
            target="_blank"
            rel="noreferrer"
            href={collaborator.url}
            className="type--small"
            css={css`
              display: grid;
              grid-template-columns: repeat(12, minmax(0, 1fr));
              gap: var(--gutter);
              position: relative;
              line-height: 2rem;
              text-decoration: none;
              padding: 2.7rem 0 2.3rem;
              border-top: 1px solid var(--black);
              opacity: ${
                (!hover || hover === collaborator) ? 1 : 0.15
              };
              z-index: 2;
              transition: opacity 0.6s var(--curve);
            `}
            onMouseEnter={() => setHover(collaborator)}
            onMouseLeave={() => setHover(null)}
          >
            <div
              className="type--medium"
              css={css`
                grid-column: span 6;
                font-weight: bold;
                text-transform: uppercase;
                line-height: 2rem;

                @media (max-width: 900px) {
                  grid-column: span 11;
                }
              `}
            >
              {collaborator.name}
              <div
                className="type--body"
                css={css`
                  display: none;
                  font-weight: normal;
                  text-transform: none;
                  margin-top: 0.5rem;

                  @media (max-width: 900px) {
                    display: block;
                  }
                `}
              >{collaborator.specialty}</div>
            </div>
            <div
              css={css`
                grid-column: span 3;

                @media (max-width: 900px) {
                  display: none;
                }
              `}
            >{collaborator.specialty}</div>
            <div
              className="type--mono"
              css={css`
                grid-column: span 2;
                line-height: 2rem;

                @media (max-width: 900px) {
                  display: none;
                }
              `}
            >{collaborator.location}</div>
            <div
              css={css`
                grid-column: span 1;
                text-align: right;
              `}
            >
              <img
                src="/images/arrow-up-right--dark.svg"
                alt="Arrow up right"
              />
            </div>
          </a>
        ))
      }
    </div>
  )
}
